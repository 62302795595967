body {
  font-family: 'Mulish', sans-serif;
}

.menuWrapper .menuBanner {
  background: #040202;
  height: 130px;
}

.menuWrapper .menuBanner img {
  width: 100%;
  height: 100%;
}

.menuWrapper .deliveryMode {
  background: #fff;
  width: 275px;
  margin: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-align: center;
  padding: 10px 0px;
  margin-top: -100px;
}

.menuWrapper .deliveryMode h2 {
  font-weight: 700;
  /* font-size: 18px; */
  font-size: 24px;
  color: #000;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.modeTab .nav-tabs {
  justify-content: center;
  border-bottom: 0;
}

.modeTab .nav-tabs .nav-link.active {
  background: #000;
  color: #fff;
  position: relative;
}

.modeTab .nav-tabs .nav-link {
  background: #e5e5e5;
  border: 0.911851px solid #4f4f4f;
  border-radius: 9px;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  padding: 1px 22px;
  display: flex;
  align-items: center;
}

.itemWrap {
  display: flex;
}

.modeTab .nav-tabs .nav-link img {
  margin-right: 10px;
}

.modeTab .nav-tabs .nav-item:first-child .nav-link {
  margin-right: -13px;
}

.modeTab .nav-tabs .nav-link:nth-child(2) {
  margin-left: -13px;
  position: relative;
  z-index: -1;
}

.menudetailMain .menuDetails .row {
  margin: 0;
}

.menuWrapper .deliveryMode .deliveryTime {
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  margin-top: 4px;
}

.menuWrapper .deliveryMode .menuHours p {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
}

.menuWrapper .deliveryMode .deliveryTime p {
  margin-bottom: 6px;
  color: #121212;
  /* font-size: 9.11851px; */
  font-size: 12px;
  /* opacity: 0.8; */
  /* font-weight: bold; */
  font-weight: 700;
}
