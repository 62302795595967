.YourOrderWrapper {
  padding: 20px 20px 0 20px;
}
.YourOrderWrapper .YourOrderHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.YourOrderWrapper .YourOrderHeading h5 {
  color: #424242;
  font-weight: 900;
  font-size: 20px;
}
.YourOrderWrapper .YourOrderHeading a {
  color: #e92f48;
  text-decoration: none;
  font-weight: 900;
  font-size: 14px;
}
.YourOrderWrapper .YourOrderInfo ul {
  padding: 0;
  list-style: none;
}
.YourOrderWrapper .YourOrderInfo ul .item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 0.5px solid rgba(126, 131, 137, 0.2);
}
.YourOrderWrapper .YourOrderInfo ul .item .item_info {
  margin-right: 20px;
  display: flex;
}
.YourOrderWrapper .YourOrderInfo ul .item .item_info .item_quantity {
  margin: 0 6px 0 0;
  width: 18px;
  height: 18px;
  min-width: 18px;
  text-align: center;
  line-height: 18px;
  color: #fff;
  background-color: #e92f48;
  border-radius: 3px;
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
}
.YourOrderWrapper .YourOrderInfo ul .item .item_info .itemName p {
  margin: 0;
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  color: #424242;
}
.YourOrderWrapper .YourOrderInfo ul .item .item_info .itemName span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7e8389;
}
.YourOrderWrapper .YourOrderInfo ul .item .item_price p {
  color: #424242;
  font-weight: normal;
  font-size: 15px;
  margin: 0;
}
.SummaryWrap {
  padding: 0 20px;
}
.SummaryWrap h5 {
  font-weight: 900;
  font-size: 20px;
  color: #424242;
  position: relative;
  /* margin-bottom: 30px; */
  padding-bottom: 2px;
}
.itemName {
  line-height: 20px;
}
/* .SummaryWrap h5::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 82px;
  border-radius: 40px;
  background-color: #e92f48;
} */
.SummaryWrap .SummaryMain ul {
  list-style: none;
  padding: 0;
}
.SummaryWrap .SummaryMain ul li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(126, 131, 137, 0.2);
  padding: 8px 0;
}
.SummaryWrap .SummaryMain ul li p {
  margin: 0;
  font-weight: normal;
  font-size: 15px;
}
.SummaryWrap .SummaryMain ul li p.title {
  color: #7e8389;
}
.SummaryWrap .SummaryMain ul li p.rate {
  color: #424242;
}
.SummaryWrap .SummaryMain ul li p.rate.discount {
  color: #5a8cd7;
}
.SummaryWrap .SummaryMain ul li.total {
  border-bottom: 0;
}
.SummaryWrap .SummaryMain ul li.total p {
  font-weight: 900;
  font-size: 18px;
  color: #424242;
}

.bottomfixbtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.bottomfixbtn button {
  width: 100%;
  background: #000000;
  height: 40.91px;
  font-size: 13px;
  border-radius: 0px 0px 12px 12px;
  color: #fefafa;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border: 0;
}
.placeOrder__groupedModifier .modifierName {
  color: #e92f48 !important;
}

.placeOrder__groupedModifier .modifierName__price {
  color: #e92f48 !important;
  content: '';
  /* display: inline-block; */
  display: flex;
  width: 0px;
}

.order__note-textArea {
  width: 100%;
  border: 0;
  background: rgba(229, 229, 229, 0.51);
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 24px;
}
