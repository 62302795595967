.successfullyorderModal .modal-content {
  background: #f2f2f2;
  border-radius: 13px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
  min-width: min-content;
  margin: auto;
}
.successfullyorderModal p {
  font-weight: normal;
  font-size: 15px;
}
.successfullyorderModal .totalPera {
  margin-bottom: 0;
}
.successfullyorderModal .backBtn {
  text-align: end;
}
.successfullyorderModal .backBtn button {
  background: #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  color: #000;
  font-weight: bold;
  font-size: 15px;
  width: 102px;
  height: 25px;
  padding: 0;
}
.successfullyorderModal .backBtn button.btnRetry {
  margin-left: 30px;
}
.successfullyorderModal .retryPera {
  margin: 40px 0px;
}
