.ProcessToPayWrapper {
  padding: 20px;
}

.ProcessToPayWrapper .totalOrder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProcessToPayWrapper .totalOrder p {
  font-weight: 900;
  font-size: 17px;
  color: #424242;
}

.ProcessToPayWrapper .addressWrap .addressHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProcessToPayWrapper .addressWrap .addressHeading h5 {
  font-weight: 900;
  font-size: 20px;
}

.ProcessToPayWrapper .addressWrap .addressHeading h5 sup {
  color: #e92f48;
  top: -0.1em;
}

.ProcessToPayWrapper .addressWrap .addressHeading a {
  color: #e92f48;
  font-weight: 900;
  font-size: 14px;
  text-decoration: none;
}

.ProcessToPayWrapper .addressWrap {
  padding: 36px 0 0 0;
}

.ProcessToPayWrapper .addressWrap textarea {
  width: 100%;
  color: red !important;
  color: #212529 !important;
  font-weight: bold;
  font-size: 12px;
  background: rgba(229, 229, 229, 0.51);
  border-radius: 10px;
  border: 0;
  padding: 4px;
}

.ProcessToPayWrapper .addressWrap textarea::placeholder {
  color: #212529 !important;
}

.ProcessToPayWrapper .addressWrap textarea.outline {
  border: 1px solid #e92f48;
}

.bottomfixbtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.bottomfixbtn button {
  width: 100%;
  background: #000000;
  height: 40.91px;
  font-weight: 800;
  font-size: 13px;
  border-radius: 0px 0px 12px 12px;
  color: #fefafa;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border: 0;
}

.AddAddress {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  border: 1px solid #7e8389;
  border-radius: 30px 30px 0 0;
}

.AddAddress .closeicon {
  width: 29px;
  height: 25px;
  background: #000000;
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  text-align: center;
}

.AddAddress .closeicon svg {
  color: #fff;
}

.AddAddress .AddAddressform {
  padding: 20px 20px 8px 20px;
}

.AddAddress .AddAddressform .input {
  padding: 0 0 6px 0;
}

.AddAddress .AddAddressform .input input {
  width: 100%;
  font-weight: 800;
  font-size: 12px;
  color: #7e8389;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 2px 4px;
}

.AddAddress .AddAddressform .inputtwo {
  display: flex;
  gap: 8px;
}

.AddAddress .AddAddressform .inputtwo .input {
  width: calc(50% - 4px);
}

.AddAddress .AddAddressform .selectTag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AddAddress .AddAddressform .selectTag p {
  margin-bottom: 0;
  font-weight: 800;
  font-size: 12px;
  color: #7e8389;
}

.AddAddress .AddAddressform .selectTag p sup {
  color: #e92f48;
}

.AddAddress .AddAddressform .selectTag button {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 65px;
  height: 20px;
  color: #000000;
  font-weight: 800;
  font-size: 10px;
}

.AddAddress .AddAddressform .selectTag button:focus {
  background-color: #e92f48;
  color: #fff;
}

.AddAddress .AddAddressBtn button {
  width: 100%;
  background-color: #000000;
  color: #fefafa;
  border: 0;
  border-radius: 0px 0px 12px 12px;
  /* height: 55.03px; */
  height: 40.91px;
  font-weight: 800;
  font-size: 13px;
}

.ProcessToPay__addresses {
  color: #212529 !important;
  height: 100px;
  font-weight: bold;
  font-size: 12px;
  background: rgba(229, 229, 229, 0.51);
  border-radius: 10px;
  border: 0;
  padding: 4px;
  margin-bottom: 10px;
  position: relative;
}

.ProcessToPay__addresses img {
  position: absolute;
  right: 10px;
  top: 40%;
}

.ProcessToPay__addresses.selected {
  box-shadow: 0 0 20px #4dabf7;
}

.ProcessToPay__addresses.notSelected {
  box-shadow: 0 0 20px indianred;
}