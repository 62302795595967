.categoriesmenuWrapper{
    padding: 20px;
}
.categoriesmenuWrapper .categoriesTitle h5{
    color: #4F4F4F;
    font-weight: 900;
    font-size: 20px;
}
.allCategoriesSection ul{
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: wrap;
    gap: 4px;
}
.allCategoriesSection ul li{
    width: calc(50% - 4px);
    position: relative;
}
.allCategoriesSection ul li img{
    width: 100%;
    /* opacity:  0.5; */
    filter:brightness(40%);
}
.allCategoriesSection ul li h6{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-weight: 800;
    font-size: 18px;
    white-space: wrap;
}
.bottomfixbtn{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}
.bottomfixbtn button{
    width: 100%;
    background: #000000;
    height: 40.91px;
    font-weight: 800;
    font-size: 13px;
    border-radius: 0px 0px 12px 12px;
    color: #FEFAFA;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    border: 0;
}
