.CategoryWrapper {
  padding: 20px;
}

.CategoryWrapper .Categoryheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Categoryorderinfo .ChooseSize .ChooseSizeHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Categoryorderinfo .ChooseSize {
  padding: 16px 0 0 0;
}

.Categoryorderinfo .ChooseSize .ChooseSizeHeading h6 {
  color: #121212;
  font-size: 16px;
  font-weight: 800;
}

.Categoryorderinfo .ChooseSize .ChooseSizeHeading .requiredBtn {
  background: rgba(241, 218, 190, 0.15);
  border-radius: 10px;
  padding: 6px 8px;
  color: #ff832c;
  font-weight: 800;
  font-size: 10px;
  border: 0;
}

.Categoryorderinfo .ChooseSize .ChooseSizeForm ul {
  padding: 0;
  list-style: none;
}

.Categoryorderinfo .ChooseSize .ChooseSizeForm ul li .radiocustomtext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid #e6e6e6;
}

.Categoryorderinfo .ChooseSize.Addon .ChooseSizeForm ul li:nth-child(2) .radiocustomtext,
.Categoryorderinfo .ChooseSize .ChooseSizeForm ul li:last-child .radiocustomtext {
  border-bottom: 0;
}

.Categoryorderinfo .ChooseSize .ChooseSizeForm ul li .radiocustomtext p {
  font-weight: normal;
  font-size: 14px;
  color: #121212;
  margin: 0;
}

.Categoryorderinfo .ChooseSize .ChooseSizeForm ul li .radiocustomtext span {
  color: #121212;
  opacity: 0.7;
  font-weight: normal;
  font-size: 14px;
}

.Categoryorderinfo .ChooseSize.SelectCrust .ChooseSizeForm ul li .radiocustomtext span {
  opacity: 1;
}

.Categoryorderinfo .ChooseSize.Addon .checkmarkcustom {
  border-radius: 0;
}

.Categoryorderinfo .ChooseSize.Addon .ChooseSizeForm ul li textarea {
  width: 100%;
  margin: 8px 0;
  border: 0;
  background: rgba(229, 229, 229, 0.51);
  font-weight: bold;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 4px;
}

.Categoryorderinfo .ChooseSize.Addon .radiocustom .checkmarkcustom:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ff832c;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: transparent;
  border-radius: 0;
}

.radiocustom {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radiocustom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkcustom {
  position: absolute;
  top: 5px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #dadada;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radiocustom:hover input~.checkmarkcustom {
  /* background-color: #ccc; */
}

/* When the radio button is checked, add a blue background */
.radiocustom input:checked~.checkmarkcustom {
  /* background-color: #2196F3; */
  border: 1px solid #ff832c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkcustom:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiocustom input:checked~.checkmarkcustom:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radiocustom .checkmarkcustom:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ff832c;
}

.item-textArea {
  margin-bottom: 24px;
}

.item-add__bottom__margin {
  margin-bottom: 24px;
}

.bottomfixbtn__confirmItem {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.bottomfixbtn__confirmItem button {
  width: 100%;
  background: #000000;
  height: 40.91px;
  font-weight: 800;
  font-size: 13px;
  border-radius: 0px 0px 12px 12px;
  color: #fefafa;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border: 0;
}

.Categoryorderinfo textarea {
  width: 100%;
  border: 0;
  background: rgba(229, 229, 229, 0.51);
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 4px;
}